
import React, { createContext } from "react";
import { Routes, Route } from "react-router-dom";
import { useAccount, useConnect, useDisconnect } from "wagmi";

import "./App.css";
import Header from "./components/Header";
import Swap from "./components/Swap";
import Liquidity from "./components/Liquidity";
// import Tokens from "./components/Tokens";

export const AppContext = createContext(null);

function App() {
    const { address, isConnected } = useAccount();
    const { connectors, connect } = useConnect();
    const { disconnect } = useDisconnect();

    const handleConnectWallet = () => {
        console.log("Handling wallet connect...");
        if (isConnected)
            disconnect();
        else {
            for (let i = 0; i < connectors.length; i++) {
                console.log("Connector:", connectors[i].uid, connectors[i].name);
                if (connectors[i].name === 'Pelagus')
                connect({ connector: connectors[i] });
            }
        }
    };

    return (
        <AppContext.Provider value={{}}>
            <div className="App">
                <Header connect={handleConnectWallet} isConnected={isConnected} address={address} />
                <div className="mainWindow">
                    <Routes>
                        <Route path="/" element={<Swap isConnected={isConnected} address={address} />} />
                        <Route path="/liquidity" element={<Liquidity />} />
                    </Routes>
                </div>
            </div>
        </AppContext.Provider>
    );
}

export default App;
