import React from "react";
import Logo from "../assets/img/QuaiSwap.png";
import Quai from "../assets/img/quai.png";
import { Link } from "react-router-dom";

function Header(props) {
    const { address, isConnected, connect } = props;

    return (
        <header>
            <div className="leftH">
                <img src={Logo} alt="logo" className="logo" />
                <Link to="/" className="link">
                    <div className="headerItem">Swap</div>
                </Link>
                <Link to="/liquidity" className="link">
                    <div className="headerItem">Liquidity</div>
                </Link>
            </div>

            <div className="rightH">
                <div className="headerItem">
                    <img src={Quai} alt="quai" className="eth"/>
                        Quai
                </div>
                <div className="connectButton" onClick={connect}>
                    {
                        isConnected && address != undefined? address.slice(0, 6) + "..." + address.slice(38) : "Connect"
                    }
                </div>
            </div>
        </header>
    );
}

export default Header;
