export const quai = {
    id: 9000,
    name: 'Quai',
    nativeCurrency: { name: 'Quai', symbol: 'QUAI', decimals: 18 },
    rpcUrls: {
        default: {
            http: ['https://graph.quaiswap.io'],
        },
    },
    blockExplorers: {
        default: {
            name: 'QuaiScan',
            url: 'https://quaiscan.io',
            apiUrl: 'https://api.quaiscan.io/api',
        },
    },
    contracts: {
        multicall3: {
            address: '0x0064E22Ab9C4c4FD1034f51D2466F100b8ee05B6',
            blockCreated: 208171,
        },
    },
};