import axios from 'axios';
import tokenList from "./tokenList.json"
import unknownToken from "../assets/img/tokenquestion.png";
import { multicall } from '@wagmi/core'
import wagmiConfig from '../util/wagmiconfig';
import curveABI from './abi/curve.json';
const pumpLogoURL = 'https://backend.poop.fun/logos/';
const CurveAddress = '0x007a811fcc40816bF87f5bf3C9be8FF3C7ec7E28';

const initialToken = {
  address: '',
  symbol: '',
  decimals: 0,
  name: '',
  img: '',
  pumpfun: false,
};
const WETH = "0x002bb272961b8C5F9d46C971e202e11F2E526A75"; // WETH token address
const QSWAP = "0x002555f7C94BeB7892e097B06c62dBe6DbE81215"; // QSWAP token address

const FetchTokenList = async () => {

  const query = `
    query MyQuery {
        tokens {
            id
            name
            symbol
            decimals
        }
    }
  `;

  const response = await axios.post('https://graph.quaiswap.io/subgraphs/name/quaiswap', {
    query: query
  })

  let tokenlist = [];

  let contracts = []

  contracts.push({
    address: CurveAddress,
    abi: curveABI,
    functionName: "allTokensLength",
    args: []
  })

  const _lengthRaw = await multicall(wagmiConfig, { contracts })
  const _length = _lengthRaw[0].status === "success" ? parseInt(_lengthRaw[0].result) : 0
  contracts = []

  for (let i = 0; i < _length; i++) {
    contracts.push({
      address: CurveAddress,
      abi: curveABI,
      functionName: "allTokens",
      args: [i]
    })
  }

  const _allTokensRaw = await multicall(wagmiConfig, { contracts })
  contracts = []
  for (let i = 0; i < _length; i++) {
    contracts.push({
      address: CurveAddress,
      abi: curveABI,
      functionName: "curveInfo",
      args: [_allTokensRaw[i].status === 'success' ? _allTokensRaw[i].result : CurveAddress]
    })
  }
  const _allCurvesRaw = await multicall(wagmiConfig, { contracts })
  const _allCurves = []
  for (let i = 0; i < _length; i++) {
    const token = _allTokensRaw[i].status === 'success' ? _allTokensRaw[i].result : ''
    const logo = _allCurvesRaw[i].status === "success" ? _allCurvesRaw[i].result[11] : ''
    const curveItem = { token, logo }

    _allCurves.push(curveItem)
  }
  console.log('allCurves', _allCurves)

  response.data.data.tokens.forEach(element => {
    const address = element.id;
    const symbol = element.symbol;
    const decimals = element.decimals;
    const name = element.name;
    let tokenImg;
    let display = false;


    if (element.id.toLowerCase() == WETH.toLowerCase()) {
      display = true;
      tokenImg = tokenList[0].img;
    } else if (element.id.toLowerCase() == QSWAP.toLowerCase()) {
      display = true;
      tokenImg = tokenList[1].img;
    } else {
      tokenImg = unknownToken;
    }

    if (symbol == "QPEPE" || symbol == "JOULIE") {
      display = true;
    }

    console.log('Allcurves', _allCurves)
    _allCurves.forEach(curve => {

      if (curve.token.toLowerCase() === element.id.toLowerCase()) {
        console.log('allcurves', curve.token.toLowerCase(), 'quaiswap', element.id.toLowerCase(), 'logo', `${pumpLogoURL}${curve.logo}`)
        if (curve.logo) {
          tokenImg = `${pumpLogoURL}${curve.logo}`;
          display = true;
        } else {
          tokenImg = unknownToken;
        }
        return;
      }
    })

    tokenlist.push(
      {
        address: address,
        symbol: symbol,
        decimals: Number(decimals),
        name: name,
        img: tokenImg,
        display: display,
      }
    );
  });
  console.log("getApolloData ", tokenlist)

  return tokenlist
}

export { FetchTokenList };
